import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './Events.module.css'
import Loader from '../loader/Loader'
import EventsList from './EventsList'
import Event from './Event'
import { sidebarElementId } from '../sidebar/Sidebars'

const HEADER_HEIGHT = 50

class Events extends Component {
  constructor(props) {
    super(props)

    this.isOnHover = false
    this.isOpen = false
    this.isDragging = false
    this.clientY = null
    this.delta = null
    this.headerNodes = {}
    this.eventNodes = {}
  }

  componentDidMount() {
    this.props.fetchAllEvents()
  }

  componentDidUpdate = (prevProps) => {
    if (
      !this.isOnHover &&
      this.props.highlightedEvents &&
      this.props.highlightedEvents.length &&
      this.props.highlightedEvents !== prevProps.highlightedEvents
    ) {
      const { id } = this.props.highlightedEvents[0]
      const node = this.eventNodes[id]
      if (node) {
        this.scrollTo(node.offsetTop - HEADER_HEIGHT)
      }
    }
  }

  onMouseEnter = () => {
    this.isOnHover = true
  }

  onMouseLeave = () => {
    this.isOnHover = false
  }

  onTouchStart = () => {
    window.addEventListener('touchmove', this.onTouchMove)
    window.addEventListener('touchend', this.onTouchEnd)
  }

  onTouchMove = (event) => {
    this.isDragging = true
    this.eventsElement.style.transform = `translate3d(0, ${Math.max(
      90,
      event.changedTouches[0].clientY
    ) - 90}px, 0)`
    this.eventsElement.style.transition = 'none'
    this.delta = event.changedTouches[0].clientY - this.clientY
    this.clientY = event.changedTouches[0].clientY
  }

  onTouchEnd = () => {
    if (this.isDragging) {
      this.isDragging = false
      this.isOpen = this.delta < 0
    } else {
      this.isOpen = !this.isOpen
    }

    this.eventsElement.style = null
    if (this.isOpen) {
      this.eventsElement.classList.remove(styles.closed)
      this.eventsElement.classList.add(styles.opened)
    } else {
      this.eventsElement.classList.remove(styles.opened)
      this.eventsElement.classList.add(styles.closed)
    }

    window.removeEventListener('touchmove', this.onTouchMove)
    window.removeEventListener('touchend', this.onTouchEnd)
  }

  onHeaderClick = (type, index) => {
    const node = this.headerNodes[type]
    if (node) {
      this.scrollTo(node.offsetTop - (index + 1) * HEADER_HEIGHT)
    }
  }

  onEventHover = (eventId, eventType) => {
    if (eventId !== this.props.selectedEventId) {
      this.props.setHighlightedEvent(eventId, eventType)
    }
  }

  scrollTo(position) {
    const sidebarNode = document.getElementById(sidebarElementId)
    if (sidebarNode) {
      sidebarNode.scrollTo({
        top: position - sidebarNode.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  render() {
    const {
      eventsByTypes,
      isLoading,
      isShowingVessel,
      highlightedEvents,
      onEventTargetClick,
      onVesselClick,
      selectedEventId,
      onEncounterClick,
      onEventTypeHighlight,
      clearHighlightedEvent,
    } = this.props

    const hasHighlightedEvents = highlightedEvents !== null && highlightedEvents.length > 0
    const highlightedEventsIds = hasHighlightedEvents ? highlightedEvents.map((e) => e.id) : []
    return (
      <div
        ref={(eventsElement) => {
          this.eventsElement = eventsElement
        }}
        className={classnames(styles.Events, styles.closed)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {isLoading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        <div className={classnames({ [styles._isLoading]: isLoading })}>
          <div className={styles.header} onTouchStart={this.onTouchStart}>
            <span>EVENTS</span>
            {/* <div className={styles.timeSelect}>
              <Select
                defaultValue={{ value: 'week', label: 'Last week' }}
                options={[
                  { value: 'week', label: 'Last week' },
                  { value: 'month', label: 'Last month' },
                  { value: '3months', label: 'Last 3 months' }
                ]}
              />
            </div> */}
          </div>
          {eventsByTypes.map((eventsByType, index) => (
            <EventsList
              index={index}
              total={eventsByTypes.length - 1}
              type={eventsByType.type}
              onHeaderClick={(type) => this.onHeaderClick(type, index)}
              key={eventsByType.type}
              title={eventsByType.label}
              count={eventsByType.eventsCount}
              onHeaderHighlight={onEventTypeHighlight}
            >
              <div
                className={styles.contentWrapper}
                ref={(node) => {
                  this.headerNodes[eventsByType.type] = node
                }}
                onMouseLeave={clearHighlightedEvent}
              >
                {eventsByType.events.map((event) => (
                  <div
                    key={event.id}
                    ref={(node) => {
                      this.eventNodes[event.id] = node
                    }}
                  >
                    <Event
                      data={event}
                      onVesselClick={onVesselClick}
                      onEncounterClick={onEncounterClick}
                      onEventTargetClick={onEventTargetClick}
                      onEventHover={this.onEventHover}
                      selectedEventId={selectedEventId}
                      isHighlighted={
                        hasHighlightedEvents && highlightedEventsIds.includes(event.id)
                      }
                      isSecondary={hasHighlightedEvents && !highlightedEventsIds.includes(event.id)}
                      showPrimaryVessel={isShowingVessel === false}
                    />
                  </div>
                ))}
                {!isShowingVessel && eventsByType.eventsCount > 25 && (
                  <span className={styles.eventsLimit}>
                    Showing the latest 25 events. Please reduce your time range or zoom in on the
                    map.
                  </span>
                )}
              </div>
            </EventsList>
          ))}
        </div>
      </div>
    )
  }
}

export default Events
