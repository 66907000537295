import { createSelector } from 'reselect'
import parseUrlParams from '../utils/parseUrlParams'

const getURLSearch = (state) => window.location.search
export const getDataset = (state) =>
  state.router.location.query && decodeURIComponent(state.router.location.query.dataset)
export const getDatasetLoading = (state) =>
  state.router.location.query && state.router.location.query.datasetVersion

export const getUrlParams = createSelector(
  [getURLSearch],
  (URLSearch) => parseUrlParams(URLSearch)
)

export const getTemporalExtent = createSelector(
  [getUrlParams],
  (urlParams) => {
    if (!urlParams.start || !urlParams.end) return null
    return [new Date(urlParams.start), new Date(urlParams.end)]
  }
)

export const getViewport = createSelector(
  [getUrlParams],
  (urlParams) => {
    return {
      center: urlParams.center,
      zoom: urlParams.zoom,
    }
  }
)

export const getActiveLayers = createSelector(
  [getUrlParams],
  (urlParams) => {
    return urlParams.layers
  }
)

export const getDatasetVersion = createSelector(
  [getUrlParams],
  (urlParams) => {
    return urlParams.datasetVersion
  }
)

export const getVesselId = createSelector(
  [getUrlParams],
  (urlParams) => urlParams && urlParams.vesselId
)

export const getSelectedEvent = createSelector(
  [getUrlParams],
  (urlParams) => urlParams && urlParams.eventId
)

export default getUrlParams
