import { updateURLParams } from '../actions'

export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT'
export const SET_HOVER_COORDS = 'SET_HOVER_COORDS'

export const updateViewport = (viewport) => ({
  type: UPDATE_VIEWPORT,
  viewport,
})

export const setZoomIncrement = (zoomIncrement) => ({ urlParams }, dispatch) => {
  const zoom = urlParams.zoom + zoomIncrement
  dispatch(setZoom(zoom))
}

export const setZoom = (zoom, center) => (params, dispatch) => {
  const newParams = { zoom }
  if (center !== undefined) {
    newParams.center = center
  }
  dispatch(updateURLParams(newParams, undefined, true))
}

export const setHoverCoords = (lat, lng) => ({
  type: SET_HOVER_COORDS,
  lat,
  lng,
})
