import { connect } from 'react-redux'
import MapCoords from './MapCoords'
import { getTemporalExtent } from '../selectors/router'

const mapStateToProps = (state) => ({
  hoverCoords: state.map.hoverCoords,
  temporalExtent: getTemporalExtent(state),
})

export default connect(mapStateToProps)(MapCoords)
