import { SET_NOTIFICATION, RESET_NOTIFICATION } from './notifications.actions'

const initialState = {
  visible: false,
  component: '',
  type: 'notification', // one of warning || error || notification
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, ...action.payload }
    case RESET_NOTIFICATION:
      return initialState
    default:
      return state
  }
}
