import { createSelector } from 'reselect'

const getVesselsData = (state) => state.vessels

export const getVesselsLoading = createSelector(
  [getVesselsData],
  (vessels) => vessels.loading
)

export const getVesselsLoaded = createSelector(
  [getVesselsData],
  (vessels) => vessels.loaded
)

export const getVesselsList = createSelector(
  [getVesselsData],
  (vessels) => vessels.list
)

export const getVesselsNextOffset = createSelector(
  [getVesselsData],
  (vessels) => vessels.list.nextOffset
)
