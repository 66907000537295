import { debounce } from 'lodash'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import { updateURLParams } from '../actions'
import GFWAPI from '@globalfishingwatch/api-client'
import qs from 'query-string'
import { workspace } from '../workspace.json'

export const UPDATE_EVENTS = 'UPDATE_EVENTS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'
export const UPDATE_VESSEL_EVENTS = 'UPDATE_VESSEL_EVENTS'
export const CLEAR_VESSEL_EVENTS = 'CLEAR_VESSEL_EVENTS'
export const UPDATE_EVENTS_STATS = 'UPDATE_EVENTS_STATS'
export const SET_HIGHLIGHTED_EVENTS = 'SET_HIGHLIGHTED_EVENTS'
export const SET_HIGHLIGHTED_EVENTS_TYPE = 'SET_HIGHLIGHTED_EVENTS_TYPE'
export const LOADING_START = 'LOADING_START'
export const LOADING_STATS_START = 'LOADING_STATS_START'

const getLayerTypes = (layers) => {
  if (!layers || !layers.length) return ''
  return layers
    .filter((l) => l !== 'events_fishing')
    .map((l) => l.split('_')[1])
    .filter((l) => l !== undefined)
    .join(',')
}

export const fetchAllEvents = () => ({ urlParams, userEvents, bounds }, dispatch) => {
  if (
    bounds === undefined ||
    urlParams.start === undefined ||
    userEvents === undefined ||
    userEvents === null ||
    (urlParams.vesselId !== undefined && urlParams.vesselId !== '') // Not needed to request all when vessel selected
  ) {
    return null
  }

  dispatch({
    type: CLEAR_VESSEL_EVENTS,
  })

  const types = getLayerTypes(userEvents)
  if (bounds && types && types.length) {
    const fetchParams = qs.stringify({
      types,
      startDate: urlParams.start,
      endDate: urlParams.end,
      bounds: `${bounds.south}, ${bounds.west}, ${bounds.north}, ${bounds.east}`,
    })

    GFWAPI.fetch(`/events?${fetchParams}`)
      .then((json) => {
        dispatch({
          type: UPDATE_EVENTS,
          events: json,
        })
      })
      .catch((error) => {
        console.warn(error)
      })
    return dispatch({
      type: LOADING_START,
    })
  }
}

export const debouncedFetchEvents = debounce((dispatch) => dispatch(fetchAllEvents()), 500)

export const fetchCurrentVesselEvents = () => ({ urlParams, userEvents }, dispatch) => {
  if (userEvents === undefined || urlParams.vesselId === undefined) {
    return null
  }
  dispatch({
    type: CLEAR_EVENTS,
  })

  const fetchParams = qs.stringify({
    types: getLayerTypes(userEvents),
    vessels: urlParams.vesselId,
  })

  GFWAPI.fetch(`/events?${fetchParams}`)
    .then((json) => {
      dispatch({
        type: UPDATE_VESSEL_EVENTS,
        events: json,
      })
    })
    .catch((error) => {
      console.warn(error)
    })

  return {
    type: LOADING_START,
  }
}

export const fetchStats = () => ({ userEvents, urlParams }, dispatch) => {
  if (!urlParams.datasetVersion) return

  const statsParams = qs.stringify({
    types: getLayerTypes(userEvents),
    interval: 'day',
  })

  GFWAPI.fetch(`/events/stats?${statsParams}`)
    .then((json) => {
      const { auto } = workspace.timeline
      if (auto !== undefined && auto.daysInnerExtent) {
        const newParams = {
          end: json.end,
          start: dayjs(json.end)
            .subtract(auto.daysInnerExtent, 'day')
            .toISOString(),
        }
        dispatch(updateURLParams(newParams, undefined, true))
      }
      dispatch({
        type: UPDATE_EVENTS_STATS,
        eventsStats: json,
      })
    })
    .catch((error) => {
      console.warn(error)
    })

  return {
    type: LOADING_STATS_START,
  }
}

export const setHighlightedEvents = (eventIds, eventsType = null, clusterId = null) => (
  { highlightedEvents },
  dispatch
) => {
  if (!isEqual(highlightedEvents, eventIds)) {
    dispatch({
      type: SET_HIGHLIGHTED_EVENTS,
      eventIds,
      eventsType,
      clusterId,
    })
  }
}

export const setHighlightedEventsType = (eventType) => ({
  type: SET_HIGHLIGHTED_EVENTS_TYPE,
  eventType,
})
