import { push, replace } from 'connected-react-router'
import qs from 'query-string'

export const updateURLParams = (newParams, newPathname, replaceState = false) => ({
  urlParams,
}) => {
  // need to remove acces token because updateURL params is not synced with redux location state
  const mergedUrlSearchParams = { ...urlParams, ...newParams, 'access-token': undefined }
  const searchString = qs.stringify(mergedUrlSearchParams)
  const action = replaceState ? replace : push
  const params = { search: searchString }
  if (newPathname !== undefined) {
    params.pathname = newPathname
  }
  return action(params)
}
