import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './LayerPanel.module.css'
import LayerSwitch from './LayerSwitch'
import { ReactComponent as IconClose } from '../icons/close.svg'

class LayerPanel extends Component {
  render() {
    const { layers, toggleLayer, toggleLayerPanel } = this.props
    return (
      <div className={styles.LayerPanel}>
        <div className={styles.header}>
          <span className={styles.title}>LAYERS</span>
          <button
            type="button"
            title="Close layer panel"
            className={styles.actionButton}
            onClick={toggleLayerPanel}
          >
            <IconClose className={styles.iconClose} />
          </button>
        </div>
        {layers.length > 0 &&
          layers.map((layer) => (
            <LayerSwitch
              key={layer.id}
              id={layer.id}
              type={layer.type}
              label={layer.label}
              color={layer.color}
              active={layer.visible}
              onToggle={(layerId, layerType) => toggleLayer(layerId, layerType)}
            />
          ))}
        {/* <LayerSwitch label='Fishing in unregistered areas' type='unregistered' active />
        <LayerSwitch label='Tracking Avoidance' type='avoidance' active />
        <LayerSwitch label='EEZ' type=''  />
        <LayerSwitch label='VMS Fishing effort' type=''  />
        <LayerSwitch label='MPA - No Take' type=''  />
        <LayerSwitch label='MPA - Restricted Use' type=''  />
        <LayerSwitch label='RFMO' type=''  /> */}
      </div>
    )
  }
}
LayerPanel.propTypes = {
  layers: PropTypes.array.isRequired,
  toggleLayer: PropTypes.func.isRequired,
  toggleLayerPanel: PropTypes.func.isRequired,
}

export default LayerPanel
