import { connect } from 'react-redux'
import { fetchStats } from '../events/Events.actions'
import SidebarHome from './SidebarHome'

const mapDispatchToProps = (dispatch) => ({
  loadEvents: () => {
    dispatch(fetchStats())
  },
})

export default connect(
  null,
  mapDispatchToProps
)(SidebarHome)
