import { createStore, applyMiddleware, compose } from 'redux'
import qs from 'query-string'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import parseUrlParams from './utils/parseUrlParams'
import rootReducer from './reducers'
import { getHighlightedEvents } from './selectors/events'
import { getUserEvents } from './selectors/layers'

const isProduction = process.env.NODE_ENV === 'production'
const basename = isProduction ? '/data-portal' : ''

export const history = createBrowserHistory({ basename })

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line

// this is a simple middleware that gives access to a limited subset of the store state
// (mostly router) to all actions that are functions
const customMiddleware = (store) => (next) => (action) => {
  // console.log(action)
  // console.log(store.getState().router.location.search);

  if (typeof action === 'function') {
    const urlParams = parseUrlParams(window.location.search)
    const hash = qs.parse(window.location.hash)
    if (hash && hash.access_token) {
      urlParams.token = hash.access_token
    }
    const transformedAction = action(
      {
        ...action,
        urlParams,
        bounds: store.getState().map.bounds,
        highlightedEvents: getHighlightedEvents(store.getState()),
        userEvents: getUserEvents(store.getState()),
      },
      store.dispatch
    )
    if (transformedAction !== undefined && transformedAction !== null) {
      next(transformedAction)
    }
  } else {
    next(action)
  }
}

const store = createStore(
  rootReducer(history),
  composeEnhancer(applyMiddleware(customMiddleware, routerMiddleware(history)))
)

export default store
