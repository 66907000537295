import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { updateURLParams } from '../actions'
import { fetchVesselsByQuery, resetVesselsSearch } from './Vessels.actions'
import Search from './Search'
import { getUrlParams } from '../selectors/router'
import { getVesselsLoaded, getVesselsList } from '../selectors/vessels'

const getSearchQuery = createSelector(
  getUrlParams,
  (urlParams) => urlParams && urlParams.query
)
const getIconText = createSelector(
  [getVesselsLoaded, getVesselsList],
  (loaded, vessels) => {
    const hasVessels = vessels.total > 0
    if (!hasVessels && loaded) return null
    return vessels.total
  }
)

const mapStateToProps = (state) => {
  return {
    initialValue: getSearchQuery(state),
    iconText: getIconText(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCloseClick: () => {
    dispatch(updateURLParams({ query: '', searchPage: 0 }, '/'))
    dispatch(resetVesselsSearch())
  },
  onSearchChange: (params) => {
    if (params.query) {
      dispatch(updateURLParams(params, '/search'))
      dispatch(
        fetchVesselsByQuery({
          query: params.query,
          page: params.searchPage,
        })
      )
    } else {
      dispatch(updateURLParams({ query: '', searchPage: 0 }, '/'))
      dispatch(resetVesselsSearch())
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
