import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CountryFlag from '@globalfishingwatch/map-components/components/countryflag'
import Loader from '../loader/Loader'
import styles from './VesselProfile.module.css'
import { ReactComponent as IconClose } from '../icons/close.svg'
import { ReactComponent as IconInfo } from '../icons/info.svg'
import { ReactComponent as IconTarget } from '../icons/target.svg'

class VesselProfile extends Component {
  constructor(props) {
    super(props)

    const isMobile = window.innerWidth <= 720

    this.state = {
      isMobile,
      infoShown: !isMobile,
    }

    this.infoFields = [
      { label: 'SSVID', key: 'ssvid', column: 'full' },
      { label: 'Gear Type', key: 'gearType', column: 'full' },
      { label: 'Length', key: 'length' },
      { label: 'Width', key: 'width' },
      { label: 'Gross Tonnage', key: 'grossTonnage' },
      { label: 'Registered fishing regions', key: 'registeredFishingRegions', column: 'full' },
      { label: 'Registration start', key: 'registrationStart', column: 'half' },
      { label: 'Registration end', key: 'registrationEnd', column: 'half' },
    ]
  }

  componentDidMount() {
    this.props.loadVessel()
  }

  componentDidUpdate(prevProps) {
    const { loadVessel, vesselId } = this.props
    if (vesselId !== prevProps.vesselId) {
      loadVessel()
    }
  }

  toggleVesselInfo = () => {
    this.setState((prevState) => ({
      infoShown: !prevState.infoShown,
    }))
  }

  render() {
    const { isMobile, infoShown } = this.state
    const { vessel, onCloseClick, loading, onVesselTargetClick } = this.props
    if (loading) return <Loader />
    return (
      <div className={styles.VesselProfile}>
        <div className={styles.header}>
          <CountryFlag iso="IDN" />
          <span className={styles.name}>{vessel.name || vessel.ssvid}</span>
          {isMobile && (
            <button
              type="button"
              title="Toggle vessel info"
              className={styles.actionButton}
              onClick={this.toggleVesselInfo}
            >
              <IconInfo className={styles.iconInfo} />
            </button>
          )}
          <button
            type="button"
            title="Close vessel profile"
            className={styles.actionButton}
            onClick={onCloseClick}
          >
            <IconClose className={styles.iconClose} />
          </button>
        </div>
        <button className={styles.targetBtn} onClick={() => onVesselTargetClick(vessel.vesselId)}>
          <IconTarget />
        </button>
        {infoShown && (
          <div className={styles.info}>
            <ul className={styles.fields}>
              {this.infoFields.map((field) => {
                return (
                  <li
                    className={cx(styles.column, styles[field.column || 'third'])}
                    key={field.key}
                  >
                    <span className={styles.label}>{field.label}</span>
                    <span className={styles.data}>{vessel[field.key] || '---'}</span>
                  </li>
                )
              })}
            </ul>
            {this.props.downloadUrl && (
              <a
                href={this.props.downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={cx(styles.actionButton, styles.downloadBtn)}
              >
                Download report
              </a>
            )}
          </div>
        )}
      </div>
    )
  }
}

VesselProfile.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  loadVessel: PropTypes.func.isRequired,
  vessel: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  vesselId: PropTypes.string.isRequired,
  onVesselTargetClick: PropTypes.func.isRequired,
}

export default VesselProfile
