import { createSelector } from 'reselect'
import { getDataset } from './router'

export const getUserData = (state) => state.app.user.data
export const getEvents = (state) => state.app.events
export const getDatasetReady = (state) => state.app.dataset && state.app.dataset.loaded
export const getDatasetLoading = (state) => state.app.dataset && state.app.dataset.loading
const getUser = (state) => state.app.user

export const isUserLoading = createSelector(
  [getUser],
  (user) => user && user.loading === true
)
export const isUserLogged = createSelector(
  [getUser],
  (user) => user !== undefined && user.logged === true
)
export const isUserAuthorized = createSelector(
  [getUser],
  (user) => user !== undefined && user.authorized === true
)

export const getUserAllowedLayers = (permissions, datasetVersion) => {
  const allowedActions = permissions
    ? permissions
        .filter((p) => p.type === 'dataset' && p.value.includes(datasetVersion.split(':')[0]))
        .map((p) => p.action.split('.')[1])
        .filter((p) => !!p)
    : []
  return allowedActions
}

export const getSupportedEvents = createSelector(
  getEvents,
  (events) => {
    return events || null
  }
)

export const getUserToken = createSelector(
  getUserData,
  (userData) => {
    if (userData === null) return null
    return userData.token || null
  }
)

export const getUserFeatures = createSelector(
  [getUserData, getDataset],
  (userData, dataset) => {
    if (userData === null || !dataset) return null
    const userLayers = getUserAllowedLayers(userData.permissions, dataset).map(
      (layer) => `events_${layer}`
    )
    return userLayers || null
  }
)
