import React, { Component, Fragment } from 'react'
import MiniGlobe from '@globalfishingwatch/map-components/components/miniglobe'
import MapCoords from './MapCoords.container'
import MapTools from './MapTools.container'
import MapWrapper from './MapWrapper.container'
import styles from './MapDashboard.module.css'

class MapDashboard extends Component {
  state = {
    isMobile: window.innerWidth <= 720,
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 720,
    })
  }

  render() {
    const { bounds, viewport } = this.props
    const { isMobile } = this.state
    return (
      <div className={styles.mapDashboard}>
        <MapCoords />
        <div className={styles.Map}>
          <MapWrapper />
        </div>
        {!isMobile && (
          <Fragment>
            <div className={styles.mapTools}>
              <MapTools />
            </div>
            <div className={styles.miniGlobe}>
              <MiniGlobe
                size={70}
                viewportThickness={1}
                center={viewport.center}
                zoom={viewport.zoom}
                bounds={bounds}
              />
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

export default MapDashboard
