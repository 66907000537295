import { connect } from 'react-redux'
import MapDashboard from './MapDashboard'
import { getUrlParams } from '../selectors/router'

const mapStateToProps = (state) => ({
  bounds: state.map.bounds,
  viewport: getUrlParams(state),
})

export default connect(mapStateToProps)(MapDashboard)
