import { connect } from 'react-redux'
import Notifications from './Notifications'
import { resetNotification } from './notifications.actions'

const mapStateToProps = (state) => ({
  visible: state.notifications.visible,
  type: state.notifications.type,
  component: state.notifications.component,
})

const mapDispatchToProps = (dispatch) => ({
  onCloseClick: () => dispatch(resetNotification()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
