import { SET_HIGHLIGHTED_EXTENT } from './Timebar.actions'

const initialState = {
  highlightedExtent: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HIGHLIGHTED_EXTENT:
      return { ...state, highlightedExtent: action.payload }
    default:
      return state
  }
}
