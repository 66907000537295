import { connect } from 'react-redux'
import { setZoomIncrement } from './Map.actions'
import { setNotification, resetNotification } from '../notifications/notifications.actions'
import MapTools from './MapTools'

const mapStateToProps = (state) => ({
  loading: state.tracks.loading,
})

const mapDispatchToProps = (dispatch) => ({
  setViewportZoom: (zoomIncrement) => {
    dispatch(setZoomIncrement(zoomIncrement))
  },
  onShareClick: () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        dispatch(
          setNotification({
            visible: true,
            type: 'notification',
            component: 'urlCopied',
          })
        )
        setTimeout(() => dispatch(resetNotification()), 4000)
      },
      (err) => {
        console.warn('Clipboard write text not supported', err)
        dispatch(
          setNotification({
            visible: true,
            type: 'warning',
            component: 'urlCopiedError',
          })
        )
        setTimeout(() => dispatch(resetNotification()), 4000)
      }
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapTools)
