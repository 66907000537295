import { UPDATE_VIEWPORT, SET_HOVER_COORDS } from './Map.actions'
import { workspace } from '../workspace.json'

const initialState = {
  hoverCoords: {
    lat: workspace.map.center[0],
    lng: workspace.map.center[1],
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_VIEWPORT:
      return {
        ...state,
        bounds: action.viewport.bounds,
      }
    case SET_HOVER_COORDS:
      return {
        ...state,
        hoverCoords: {
          lat: action.lat,
          lng: action.lng,
        },
      }
    default:
      return state
  }
}
