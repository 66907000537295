import { connect } from 'react-redux'
import { HEATMAP } from '../constants'
import { getAllLayers } from '../selectors/layers'
import { toggleLayer, toggleHeatmapLayer } from './Layers.actions'
import LayerPanel from './LayerPanel'

const mapStateToProps = (state) => ({
  layers: getAllLayers(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleLayer: (layerId, layerType) => {
    if (layerType === HEATMAP) {
      dispatch(toggleHeatmapLayer())
    } else {
      dispatch(toggleLayer(layerId))
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerPanel)
