import { TRACK_COLORS } from '../constants'
import GFWAPI from '@globalfishingwatch/api-client'
import geobuf from 'geobuf'
import Pbf from 'pbf'
import qs from 'query-string'

export const FETCH_TRACK_DATA_ERROR = 'FETCH_TRACK_DATA_ERROR'
export const FETCHING_TRACK_DATA = 'FETCHING_TRACK_DATA'
export const SET_TRACK_DATA = 'SET_TRACK_DATA'
export const SET_TRACK_INFO = 'SET_TRACK_INFO'
export const RESET_TRACK = 'RESET_TRACK'

export const fetchVesselTrack = (vesselId) => (currentParams, dispatch) => {
  dispatch(resetTrack())
  dispatch({
    type: SET_TRACK_INFO,
    payload: {
      id: vesselId,
      color: TRACK_COLORS.main,
      type: 'geojson',
    },
  })
  dispatch({ type: FETCHING_TRACK_DATA })
  const params = qs.stringify({ features: 'fishing', binary: true })
  const url = `/vessels/${vesselId}/tracks?${params}`
  GFWAPI.fetch(url, { json: false })
    .then((r) => r.arrayBuffer())
    .then((buffer) => geobuf.decode(new Pbf(buffer)))
    .then((data) => {
      const geojson = {
        ...data,
        features: data.features.filter(
          (feature) =>
            feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length
        ),
      }
      dispatch({
        type: SET_TRACK_DATA,
        payload: geojson,
      })
    })
    .catch((error) => {
      console.warn(error)
      dispatch({
        type: FETCH_TRACK_DATA_ERROR,
        payload: error,
      })
    })
}

export const resetTrack = () => ({
  type: RESET_TRACK,
})
