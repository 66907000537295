import React from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'
import MapDashboard from '../map/MapDashboard.container'
import TimebarWrapper from '../timebar/TimebarWrapper.container'
import Loader from '../loader/Loader'
import Login from '../login/Login'
import Notifications from '../notifications/notifications.container.js'
import styles from './App.module.css'

import { Sidebar, SidebarSearch, SidebarVessel } from '../sidebar/Sidebars'
import SidebarHome from '../sidebar/SidebarHome.container'

class App extends React.Component {
  componentDidMount = () => {
    this.props.checkUserLogged()
  }

  onUpdateClick = () => {
    this.props.updateDatasetVersion()
  }

  render() {
    const { loading, logged, datasetReady, authorized, history } = this.props

    if (loading || !datasetReady) {
      return (
        <div className={styles.App}>
          <Loader />
        </div>
      )
    }

    if (!logged) {
      return (
        <div className={styles.App}>
          <Login authorized={authorized} />
        </div>
      )
    }

    return (
      <ConnectedRouter history={history}>
        <div className={styles.App}>
          <Sidebar>
            <Switch>
              <Route exact path="/" component={SidebarHome} />
              <Route path="/search" component={SidebarSearch} />
              <Route path="/vessel" component={SidebarVessel} />
              <Route component={SidebarHome} />
            </Switch>
          </Sidebar>
          <MapDashboard />
          <TimebarWrapper />
          <Notifications>
            {(component) => (
              <div>
                {component === 'outdatedDataset' && (
                  <span>
                    Dataset version is outdated. Click{' '}
                    <button className={styles.notificationBtn} onClick={this.onUpdateClick}>
                      here
                    </button>{' '}
                    to use the latest version instead.
                  </span>
                )}
                {component === 'urlCopied' && (
                  <span>
                    A link to the Data Portal in its current state has been copied to the clipboard
                  </span>
                )}
                {component === 'urlCopiedError' && (
                  <span>
                    The link couldn't be copied to the clipboard, please select it manually from the
                    browser
                  </span>
                )}
              </div>
            )}
          </Notifications>
        </div>
      </ConnectedRouter>
    )
  }
}

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  logged: PropTypes.bool.isRequired,
  checkUserLogged: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
}

export default App
