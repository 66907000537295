import { createSelector } from 'reselect'
import { getDataset, getActiveLayers } from '../selectors/router'
import { getUserFeatures } from '../selectors/app'
import { API_GATEWAY } from '../constants'

const EVENT_LAYER_IDS = [
  'events_fishing',
  'events_encounter',
  'events_gap',
  'events_port',
  // 'events_unregistered',
]

const getLayersSettings = (state) => state.layers.layers
const getHeatmapLayer = (state) => state.layers.heatmapLayer

export const getUserLayers = createSelector(
  [getLayersSettings, getUserFeatures],
  (layers, userFeatures) => {
    return userFeatures !== null
      ? layers.filter((layer) => {
          return layer.id.includes('event') ? userFeatures.includes(layer.id) : true
        })
      : []
  }
)

export const getUserEvents = createSelector(
  [getUserLayers],
  (userLayers) => {
    return userLayers.map((l) => l.id)
  }
)

export const getVisibleLayers = createSelector(
  [getActiveLayers, getUserLayers],
  (activeLayers, layers) => {
    return layers.map((layer) => {
      const visible = activeLayers !== undefined && activeLayers.includes(layer.id)
      return {
        ...layer,
        visible,
      }
    })
  }
)

export const getBaseLayers = createSelector(
  [getVisibleLayers, getDataset],
  (layers, dataset) => {
    return layers.map((layer) => {
      const isEvent = EVENT_LAYER_IDS.includes(layer.id)
      if (!isEvent) {
        return {
          ...layer,
          color: layer.color,
        }
      }
      const eventType = layer.id.split('_')[1]
      return {
        ...layer,
        interactive: true,
        url: `${API_GATEWAY}/datasets/${dataset}/events/${eventType}/tiles/{z}/{x}/{y}`,
      }
    })
  }
)

export const getAllLayers = createSelector(
  [getVisibleLayers, getHeatmapLayer],
  (layers, heatmapLayer) => {
    const allLayers = [...layers]
    if (heatmapLayer) {
      allLayers.push(heatmapLayer)
    }
    return allLayers
  }
)
