import { RESULTS_PER_PAGE } from '../constants'
import { fetchVesselTrack } from '../tracks/tracks.actions'
import qs from 'query-string'
import GFWAPI from '@globalfishingwatch/api-client'

export const FETCH_VESSELS_INFO_ERROR = 'FETCH_VESSELS_INFO_ERROR'
export const FETCHING_VESSELS_INFO = 'FETCHING_VESSELS_INFO'
export const SET_CURRENT_VESSEL = 'SET_CURRENT_VESSEL'
export const SET_VESSELS_LIST = 'SET_VESSELS_LIST'
export const RESET_VESSELS = 'RESET_VESSELS'

export const fetchCurrentVessel = () => ({ urlParams }, dispatch) => {
  const { vesselId } = urlParams
  if (vesselId === undefined) return null

  dispatch(fetchVesselTrack(vesselId))
  dispatch({ type: RESET_VESSELS })
  dispatch({ type: FETCHING_VESSELS_INFO })
  GFWAPI.fetch(`/vessels/${vesselId}`)
    .then((data) => {
      dispatch({
        type: SET_CURRENT_VESSEL,
        payload: data,
      })
    })
    .catch((error) => {
      console.warn(error)
      dispatch({
        type: FETCH_VESSELS_INFO_ERROR,
        payload: error,
      })
    })
}

export const fetchVesselsByQuery = ({ query, page }) => ({ urlParams }, dispatch) => {
  dispatch({ type: FETCHING_VESSELS_INFO })
  const params = {
    query: query || urlParams.query,
    limit: RESULTS_PER_PAGE,
    offset: (page || urlParams.page || 0) * RESULTS_PER_PAGE,
  }
  GFWAPI.fetch(`/vessels?${qs.stringify(params)}`)
    .then((data) => {
      dispatch({
        type: SET_VESSELS_LIST,
        payload: data,
      })
    })
    .catch((error) => {
      console.warn(error)
      dispatch({
        type: FETCH_VESSELS_INFO_ERROR,
        payload: error,
      })
    })
}

export const resetVesselsSearch = () => ({
  type: RESET_VESSELS,
})
