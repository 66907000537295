import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './LayerSwitch.module.css'

class LayerSwitch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: this.props.active,
    }
  }

  toggleLayer = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    const { active, id, type, color, label, onToggle } = this.props

    return (
      <div className={styles.LayerSwitch}>
        <button
          type="button"
          className={classnames(styles.switch, { [styles.active]: active })}
          style={{ backgroundColor: active ? color : 'initial' }}
          onClick={() => onToggle(id, type)}
        />
        <span className={styles.label}>{label}</span>
      </div>
    )
  }
}

LayerSwitch.propTypes = {
  active: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

LayerSwitch.defaultProps = {
  type: 'events',
}

export default LayerSwitch
