import qs from 'query-string'
import { getTimebarRangeByWorkspace } from '@globalfishingwatch/map-components/components/timebar/utils'
import { workspace } from '../workspace.json'
import { DEFAULT_LAYERS } from '../constants'

export const getDefaultUrlParams = () => {
  const { map, timeline } = workspace
  const timerange = getTimebarRangeByWorkspace(timeline)
  const defaults = {
    zoom: map.zoom || 5,
    center: map.center || [0.02606, 123.61378],
    layers: map.layers.filter((l) => l.visible).map((l) => l.id) || DEFAULT_LAYERS,
    start: new Date(timerange.start).toISOString() || '2017-01-01T00:00:00.000Z',
    end: new Date(timerange.end).toISOString() || '2018-01-01T00:00:00.000Z',
  }
  return defaults
}

export default (urlSearch) => {
  const urlSearchParams = urlSearch ? qs.parse(urlSearch) : getDefaultUrlParams()
  const { center, layers, zoom } = urlSearchParams
  if (layers !== undefined) {
    // query-string parse as a string when having only one layer
    // to simplify it we always use arrays so have to convert string to array
    urlSearchParams.layers = typeof layers === 'string' ? [layers] : layers
  }
  // URL error/undefined values handling
  if (center !== undefined) {
    urlSearchParams.center = center.map((c) => parseFloat(c))
  }
  if (zoom !== undefined) {
    urlSearchParams.zoom = parseFloat(zoom)
  }
  return urlSearchParams
}
