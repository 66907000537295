import React, { Component } from 'react'
import styles from './Header.module.css'
import { ReactComponent as GFWLogo } from './img/GFW-logo.svg'

class Header extends Component {
  render() {
    return (
      <div className={styles.Header}>
        <GFWLogo />
        <button type="button" className={styles.logoutBtn} onClick={this.props.logout}>
          LOGOUT
        </button>
      </div>
    )
  }
}

export default Header
