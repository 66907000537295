// Not needed to add proptypes as they are already checked in MapModule
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { DATE_FORMAT_LONG, EVENT_TYPES } from '../constants'
import MapModule from '@globalfishingwatch/map-components/components/map'
import styles from './MapWrapper.module.css'

class MapWrapper extends Component {
  state = {
    hoverPopupPosition: null,
    popupData: null,
  }

  // TODO this should be optional
  loadTemporalExtent = [new Date(2018, 0, 1), new Date(2018, 11, 31)]

  onAttributionsChange = (attributions) => {
    this.setState({ attributions })
  }

  onMapHover = (event) => {
    const { isShowingVessel, setHighlightedEvent, setHoverCoords } = this.props
    const isStaticEvent = event.type === 'static'
    const isCluster = event.cluster !== undefined

    setHoverCoords(event.latitude, event.longitude)

    let eventIds = null
    let clusterId = null
    if (isStaticEvent && isShowingVessel === true) {
      if (!isCluster) {
        eventIds = [event.target.featureTitle]
      } else {
        eventIds = event.cluster.children.map((feature) => feature.properties.event_id)
        clusterId = event.target.properties.cluster_id
      }
    }
    const highlightedType = event.layer !== undefined ? event.layer.id.replace('_vessel', '') : null
    setHighlightedEvent(
      isStaticEvent && isShowingVessel === true ? eventIds : null,
      highlightedType,
      clusterId
    )

    if (event && event.target && isStaticEvent && !isCluster) {
      const { timestamp, event_type, event_id: eventId } = event.target.properties
      const eventType = EVENT_TYPES.find((e) => e.id === event_type) || {}
      this.setState({
        hoverPopupPosition: {
          latitude: event.latitude,
          longitude: event.longitude,
        },
        popupData: {
          eventId,
          timestamp,
          layer: event.layer.id,
          eventType: eventType.label,
        },
      })
    } else {
      this.setState({
        hoverPopupPosition: null,
        popupData: null,
      })
    }
  }

  onMapClick = (event) => {
    const { selectedEvent } = this.props
    const properties = event.feature && event.feature.properties
    const layerId = event.feature !== undefined ? event.feature.layer.id : ''
    if (properties !== undefined && properties.vessel_id !== undefined) {
      if (properties.event_id !== undefined && layerId.includes('encounter')) {
        if (properties.event_id === selectedEvent) {
          this.props.onEncounterClick(properties.vessel_id)
        } else {
          this.props.onEncounterClick(properties.vessel_id, properties.event_id)
        }
      } else {
        this.props.onEventClick(properties.vessel_id)
      }
    } else if (event.cluster !== undefined) {
      this.props.onClusterClick(event.cluster.zoom, event.latitude, event.longitude)
    }
  }

  onLoad = (bounds) => {
    const viewport = { ...this.props.viewport, bounds }
    this.props.updateViewport(viewport)
  }

  onViewportChange = (viewport) => {
    this.debouncedUpdateViewPort(viewport)
  }

  debouncedUpdateViewPort = debounce((viewport) => {
    this.props.updateViewport(viewport)
  })

  getHoverPopup() {
    const { popupData, hoverPopupPosition } = this.state
    const { selectedEvent } = this.props
    if (!popupData) return null
    const date =
      popupData.timestamp === undefined
        ? '?'
        : dayjs.unix(popupData.timestamp).format(DATE_FORMAT_LONG)
    const action = selectedEvent === popupData.eventId ? 'hide' : 'show'
    const content = (
      <div className={styles.popupContainer}>
        <p className={styles.popupEvent}> {popupData.eventType} </p>
        <p className={styles.popupDate}> {date} </p>
        {popupData.layer === 'events_encounter_vessel' && (
          <p className={styles.popupAction}>Click to {action} encountered vessel track</p>
        )}
      </div>
    )
    return {
      ...hoverPopupPosition,
      content,
    }
  }

  render() {
    const {
      token,
      highlightExtent,
      temporalExtent,
      viewport,
      layers,
      tracks,
      heatmapLayers,
    } = this.props
    const { attributions } = this.state
    const hoverPopup = this.getHoverPopup()

    return (
      <Fragment>
        {attributions !== undefined && (
          <span
            className={styles.attributions}
            dangerouslySetInnerHTML={{ __html: attributions.join(' · ') }}
          />
        )}
        <MapModule
          token={token}
          tracks={tracks}
          viewport={viewport}
          temporalExtent={temporalExtent || this.loadTemporalExtent}
          highlightTemporalExtent={highlightExtent}
          loadTemporalExtent={this.loadTemporalExtent}
          staticLayers={layers}
          heatmapLayers={heatmapLayers}
          hoverPopup={hoverPopup}
          onViewportChange={this.onViewportChange}
          onLoad={this.onLoad}
          onHover={this.onMapHover}
          onClick={this.onMapClick}
          onAttributionsChange={this.onAttributionsChange}
        />
      </Fragment>
    )
  }
}

export default MapWrapper
