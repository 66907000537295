import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { groupBy } from 'lodash'
import { VESSEL_PATH, EVENT_TYPES, EVENT_TYPES_IDS } from '../constants'
import { updateURLParams } from '../actions'
import { fetchAllEvents, setHighlightedEventsType, setHighlightedEvents } from './Events.actions'
import { getEvents, getVesselEvents, getHighlightedEventsWithData } from '../selectors/events'
import { getVesselId, getTemporalExtent, getSelectedEvent } from '../selectors/router'
import { eventIsWithinTemporalExtent } from '../utils/eventIsWithinTemporalExtent'
import Events from './Events'

const getIsShowingVessel = createSelector(
  [getVesselId],
  (vesselId) => {
    if (vesselId !== undefined && vesselId !== '') return true
    return false
  }
)

const getEventsByTypes = createSelector(
  [getEvents, getVesselEvents, getIsShowingVessel, getTemporalExtent],
  (events, vesselEvents, isShowingVessel, temporalExtent) => {
    const displayedEvents = isShowingVessel === false ? events : vesselEvents
    if (displayedEvents === undefined || displayedEvents === null || temporalExtent === null) {
      return []
    }
    let filteredEvents = displayedEvents.filter((event) => event.type !== EVENT_TYPES_IDS.FISHING)
    if (isShowingVessel) {
      // filter for all events already done in API
      filteredEvents = filteredEvents.filter((event) => {
        const eventStart = new Date(event.start).getTime()
        const eventEnd = new Date(event.end).getTime()
        return eventIsWithinTemporalExtent(eventStart, eventEnd, temporalExtent)
      })
    }
    const groupedBy = groupBy(
      // TODO use the correct API filter instead
      filteredEvents,
      (event) => event.type
    )

    const eventTypes = Object.keys(groupedBy)
    eventTypes.sort()

    return eventTypes.map((type) => {
      let eventsForType = groupedBy[type]
      const eventsCount = eventsForType.length
      // show latest events first
      eventsForType.sort((a, b) => {
        return new Date(b.start).getTime() - new Date(a.start).getTime()
      })
      // TODO currently limit is set to 25 arbitrarily (except when showing vessel), but we need to implement pagination
      if (isShowingVessel === false) {
        eventsForType = eventsForType.slice(0, 25)
      }
      return {
        type,
        label: EVENT_TYPES.find((t) => t.id === type).label,
        events: eventsForType,
        eventsCount,
      }
    })
  }
)

const mapStateToProps = (state) => ({
  eventsByTypes: getEventsByTypes(state),
  isLoading: state.events.isLoading,
  highlightedEvents: getHighlightedEventsWithData(state),
  selectedEventId: getSelectedEvent(state),
  isShowingVessel: getIsShowingVessel(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllEvents: () => {
    dispatch(fetchAllEvents())
  },
  onVesselClick: (vesselId) => {
    const newParams = { vesselId, eventId: undefined }
    dispatch(updateURLParams(newParams, VESSEL_PATH))
  },
  onEncounterClick: (eventId) => {
    const newParams = { eventId }
    dispatch(updateURLParams(newParams, VESSEL_PATH))
  },
  onEventTargetClick: (event) => {
    if (event !== undefined && event.position !== undefined) {
      const center = [event.position.lat, event.position.lon]
      dispatch(updateURLParams({ center }, undefined, true))
    }
  },
  onEventTypeHighlight: (type) => {
    dispatch(setHighlightedEventsType(type))
  },
  setHighlightedEvent: (eventId, eventType = null) => {
    const eventIds = eventId === null ? null : [eventId]
    dispatch(setHighlightedEvents(eventIds, eventType))
  },
  clearHighlightedEvent: () => {
    dispatch(setHighlightedEvents([], null))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events)
