import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import styles from './EventsList.module.css'

// Styles for the header
const HEADER_SIZE = 50

class EventsList extends Component {
  onHeaderClick = () => {
    const { type, onHeaderClick } = this.props
    onHeaderClick(type)
  }

  onMouseEnter = () => {
    const { onHeaderHighlight, type } = this.props
    onHeaderHighlight(type)
  }

  onMouseLeave = () => {
    const { onHeaderHighlight } = this.props
    onHeaderHighlight(null)
  }

  render() {
    const { index, total, type, title, count, children } = this.props
    const stickyPosition = {
      top: index === 0 ? 0 : index * HEADER_SIZE,
      bottom: index === total ? 0 : index * HEADER_SIZE,
    }
    return (
      <Fragment>
        <div
          className={classNames(styles.header, styles[type])}
          onClick={this.onHeaderClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          style={stickyPosition}
        >
          <span className={styles.title}>{title}</span>
          <div className={styles.countWrapper}>
            <span className={styles.count}>({count})</span>
          </div>
        </div>
        {children}
      </Fragment>
    )
  }
}

export default EventsList
