import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { debounce } from 'lodash'
import styles from './Search.module.css'
import { ReactComponent as IconClose } from '../icons/close.svg'
import { ReactComponent as IconSearch } from '../icons/search.svg'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue,
    }
  }

  componentDidMount = () => {
    if (this.props.initialValue) {
      this.updateSearch(this.props.initialValue)
    }
  }

  onSearchChangeDebounced = debounce((value) => this.updateSearch(value), 300)

  onSearchChangeHandler = (e) => {
    const { value } = e.target
    this.setState({ value })
    this.onSearchChangeDebounced(value)
  }

  updateSearch = (query) => {
    const { onSearchChange } = this.props
    onSearchChange({
      query,
      searchPage: 0,
    })
  }

  render() {
    const { value } = this.state
    const { expanded, onCloseClick, iconText } = this.props
    return (
      <div className={classNames(styles.Search, { [styles.expanded]: expanded })}>
        <input
          autoFocus
          type="text"
          placeholder="Search vessel (Name or VMS ID)"
          value={value}
          onChange={this.onSearchChangeHandler}
        />
        {iconText !== null && <span className={styles.iconText}>{iconText}</span>}
        <button
          type="button"
          title="Search vessels"
          className={styles.actionButton}
          onClick={onCloseClick}
        >
          {expanded ? (
            <IconClose className={styles.iconClose} />
          ) : (
            <IconSearch className={styles.iconSearch} />
          )}
        </button>
      </div>
    )
  }
}

Search.propTypes = {
  expanded: PropTypes.bool,
  iconText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValue: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
}

Search.defaultProps = {
  initialValue: '',
  iconText: null,
  expanded: false,
}

export default Search
