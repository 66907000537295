export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
})

export const setNotification = (payload) => ({
  type: SET_NOTIFICATION,
  payload,
})
