import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import NotificationStyles from './Notifications.module.css'
import { ReactComponent as IconClose } from '../icons/close.svg'

const Notifications = ({ visible, type, children, content, component, onCloseClick }) => {
  if (!visible) return null
  return (
    <div className={cx(NotificationStyles.banner, NotificationStyles[type])}>
      {children && children(component)}
      <button
        type="button"
        title="Close vessel profile"
        className={NotificationStyles.actionButton}
        onClick={onCloseClick}
      >
        <IconClose className={NotificationStyles.iconClose} />
      </button>
    </div>
  )
}

Notifications.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['notification', 'warning', 'error']),
  component: PropTypes.string, // Id of the component to use
  onCloseClick: PropTypes.func.isRequired,
}

Notifications.defaultProps = {
  visible: false,
  component: null,
}

export default Notifications
