import { createSelector } from 'reselect'
import { getSelectedEvent, getVesselId } from './router'

export const getEvents = (state) => state.events.events
export const getEventsStats = (state) => state.events.eventsStats
export const getHighlightedEventsType = (state) => state.events.highlightedEventsType
export const getHighlightedEvents = (state) => state.events.highlightedEvents
export const getHighlightedEventsParentType = (state) => state.events.highlightedEventsParentType
export const getHighlightedClusterId = (state) => state.events.highlightedClusterId
export const getVesselEvents = (state) => state.events.vesselEvents

export const getAllHighlightedMapEvents = createSelector(
  [getHighlightedEvents, getHighlightedClusterId, getHighlightedEventsParentType, getSelectedEvent],
  (eventsIds, highlightedClusterId, highlightedEventsParentType, selectedEventId) => {
    if (eventsIds === null && highlightedClusterId === null) {
      return selectedEventId !== undefined ? { eventsIds: [selectedEventId] } : null
    }
    if (selectedEventId === undefined) {
      // type only matters for clusters as clusters can have the same ids over different layers
      return highlightedClusterId !== null
        ? { eventsIds: [highlightedClusterId], isCluster: true, type: highlightedEventsParentType }
        : { eventsIds }
    }
    // Needs to remove the selected from highlight to avoid duplications
    const eventsWithoutSelected = eventsIds.filter((e) => e !== selectedEventId)
    // Order matters because the scroll to element will go always to the first one
    // this make possible to scroll to the new and and when no highlighted anymore
    // it goes back to the selected one
    return { eventsIds: [...eventsWithoutSelected, selectedEventId] }
  }
)

export const getEncounterEvent = createSelector(
  [getSelectedEvent, getVesselEvents],
  (eventId, events) => {
    if (eventId === undefined || events === null) return null
    const event = events.find((e) => e.id === eventId)
    return event || null
  }
)

const getIsShowingVessel = createSelector(
  [getVesselId],
  (vesselId) => {
    if (vesselId !== undefined && vesselId !== '') return true
    return false
  }
)

export const getHighlightedEventsWithData = createSelector(
  [getVesselEvents, getIsShowingVessel, getHighlightedEvents, getSelectedEvent],
  (events, isShowingVessel, highlightedIds, selectedEvent) => {
    if (!events || !events.length || !isShowingVessel) return null
    const eventsFiltered =
      events.filter(
        (event) => (highlightedIds || []).includes(event.id) || event.id === selectedEvent
      ) || null
    return eventsFiltered
  }
)
