import React, { Component } from 'react'
import dayjs from 'dayjs'
import formatcoords from 'formatcoords'
import { DATE_FORMAT } from '../constants'
import styles from './MapCoords.module.css'

class MapCoords extends Component {
  state = {
    decimal: false,
    willChange: false,
  }
  onMouseEnter = () => {
    const { decimal } = this.state
    this.setState({
      decimal: !decimal,
    })
  }
  onClick = () => {
    this.setState({
      willChange: true,
    })
  }
  onMouseLeave = () => {
    const { willChange, decimal } = this.state
    this.setState({
      decimal: willChange === false ? !decimal : decimal,
      willChange: false,
    })
  }

  render() {
    const { temporalExtent, hoverCoords } = this.props
    const { decimal } = this.state
    return (
      <div
        className={styles.MapCoords}
        onMouseEnter={this.onMouseEnter}
        onClick={this.onClick}
        onMouseLeave={this.onMouseLeave}
      >
        {temporalExtent && (
          <span>
            {dayjs(temporalExtent[0]).format(DATE_FORMAT)} ‒{' '}
            {dayjs(temporalExtent[1]).format(DATE_FORMAT)}
          </span>
        )}
        <span className={styles.center}>
          {decimal
            ? `${hoverCoords.lat}, ${hoverCoords.lng}`
            : formatcoords(hoverCoords).format('FFf', {
                decimalPlaces: 3,
              })}
        </span>
      </div>
    )
  }
}

export default MapCoords
