import {
  SET_TRACK_INFO,
  SET_TRACK_DATA,
  FETCHING_TRACK_DATA,
  FETCH_TRACK_DATA_ERROR,
  RESET_TRACK,
} from './tracks.actions'

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  track: {
    id: null,
    type: '',
    color: '',
    data: null,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_TRACK:
      return initialState
    case FETCHING_TRACK_DATA:
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    case SET_TRACK_INFO:
      return {
        ...state,
        track: { ...state.track, ...action.payload },
      }
    case SET_TRACK_DATA:
      return {
        ...state,
        loading: false,
        loaded: true,
        track: {
          ...state.track,
          data: action.payload,
        },
      }
    case FETCH_TRACK_DATA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    default:
      return state
  }
}
