import React, { Component } from 'react'
import Timebar, {
  TimebarVesselEvents,
  TimebarEvents,
} from '@globalfishingwatch/map-components/components/timebar'
import styles from './TimebarWrapper.module.css'
import {
  TIMEBAR_HOVER_HIGHLIGHT_HOUR_RANGE_PX,
  TIMEBAR_HOVER_HIGHLIGHT_RANGE_PX,
} from '../constants'

class TimebarWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bookmarkStart: null,
      bookmarkEnd: null,
    }
  }

  onMouseLeave = () => {
    this.props.setHighlightedExtent(null)
  }

  onMouseMove = (clientX, scale, isDay) => {
    const { setHighlightedExtent } = this.props
    const range = isDay ? TIMEBAR_HOVER_HIGHLIGHT_HOUR_RANGE_PX : TIMEBAR_HOVER_HIGHLIGHT_RANGE_PX
    const highlightedStart = scale(clientX - range)
    const highlightedEnd = scale(clientX + range)
    setHighlightedExtent([highlightedStart, highlightedEnd])
  }

  onEventClick = (event) => {
    if (!event || event.type !== 'encounter') return

    if (event.id !== undefined && event.id === this.props.selectedEventID) {
      this.props.onEncounterClick()
    } else {
      this.props.onEncounterClick(event.id)
    }
  }

  updateBookmark = (bookmarkStart, bookmarkEnd) => {
    this.setState({
      bookmarkStart,
      bookmarkEnd,
    })
  }

  onTimebarChange = (start, end) => {
    const { temporalExtent, setTemporalExtent } = this.props
    if (start !== temporalExtent[0] || end !== temporalExtent[1]) {
      setTemporalExtent(start, end)
    }
  }

  render() {
    const { bookmarkStart, bookmarkEnd } = this.state
    const {
      absoluteExtent,
      temporalExtent,
      isVesselShown,
      eventsStats,
      vesselEvents,
      setHighlightedEvent,
      selectedEventID,
      highlightedEvents,
    } = this.props

    return temporalExtent ? (
      <div className={styles.timebarWrapper}>
        <Timebar
          start={temporalExtent[0]}
          end={temporalExtent[1]}
          bookmarkStart={bookmarkStart}
          bookmarkEnd={bookmarkEnd}
          absoluteStart={absoluteExtent[0]}
          absoluteEnd={absoluteExtent[1]}
          onChange={this.onTimebarChange}
          onBookmarkChange={this.updateBookmark}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
        >
          {(props) => {
            if (isVesselShown === true && vesselEvents !== undefined && vesselEvents !== null) {
              return (
                <TimebarVesselEvents
                  {...props}
                  events={vesselEvents}
                  selectedEventID={selectedEventID}
                  highlightedEventIDs={highlightedEvents}
                  onEventClick={this.onEventClick}
                  onEventHighlighted={(event) =>
                    setHighlightedEvent(
                      event === undefined ? null : event.id,
                      event === undefined ? null : event.type
                    )
                  }
                />
              )
            }
            if (eventsStats !== undefined) {
              return <TimebarEvents {...props} events={eventsStats} showFishing={false} />
            }
            return null
          }}
        </Timebar>
      </div>
    ) : null
  }
}

export default TimebarWrapper
