import qs from 'query-string'
import { replace } from 'connected-react-router'
import { resetNotification, setNotification } from '../notifications/notifications.actions'
import { updateURLParams } from '../actions'
import { getDefaultUrlParams } from '../utils/parseUrlParams'
import GFWAPI from '@globalfishingwatch/api-client'
import { getUserAllowedLayers } from '../selectors/app'
import { QUERY_PARAMS_KEY, DEFAULT_DATASET_ID, DEFAULT_LAYERS } from '../constants'
import { removeLocalToken } from '../utils/login'
import uniq from 'lodash/uniq'

export const SET_DATASET_LOADING = 'SET_DATASET_LOADING'
export const SET_DATASET_DATA = 'SET_DATASET_DATA'
export const SET_DATASET_ERROR = '  SET_DATASET_ERROR'
export const SET_USER_LOADING = 'SET_USER_LOADING'
export const SET_USER_LOGGED = 'SET_USER_LOGGED'
export const SET_USER_UNAUTHORIZED = 'SET_USER_UNAUTHORIZED'
export const SET_USER_LOGOUT = 'SET_USER_LOGOUT'
export const SET_SUPPORTED_EVENTS = 'SET_SUPPORTED_EVENTS'

export const redirectToLogin = () => {
  const [urlPath, urlQuery] = window.location.href.split('?')
  sessionStorage.setItem(QUERY_PARAMS_KEY, urlQuery)
  const redirectUri = decodeURIComponent(urlPath)
  const url = GFWAPI.getLoginUrl(redirectUri)
  window.location = url
}

export const logoutUser = () => ({ urlParams }, dispatch) => {
  dispatch({
    type: SET_USER_LOGOUT,
  })
  removeLocalToken()
  redirectToLogin()
}

export const setDatasetLoading = (loading = true) => ({
  type: SET_DATASET_LOADING,
  payload: loading,
})

export const setDatasetData = (dataset) => ({
  type: SET_DATASET_DATA,
  payload: dataset,
})

export const setDatasetEror = (error) => ({
  type: SET_DATASET_ERROR,
  payload: error,
})

export const setSupportedEvents = (events) => ({
  type: SET_SUPPORTED_EVENTS,
  payload: events,
})

export const initDataset = (user) => ({ urlParams }, dispatch) => {
  const datasetID = urlParams.dataset || DEFAULT_DATASET_ID
  const canLoadDataset =
    user &&
    user.permissions &&
    user.permissions.find(
      (p) =>
        p.type === 'dataset' && p.value.indexOf(datasetID.split(':')[0]) > -1 && p.action === 'read'
    ) !== undefined

  if (canLoadDataset) {
    dispatch(setDatasetLoading())
    GFWAPI.fetch(`/datasets/${datasetID}`)
      .then((dataset) => {
        const { id, supportedEventTypes } = dataset
        const datasetVersion = urlParams.datasetVersion || id
        if (urlParams.datasetVersion) {
          const isDeprecated = urlParams.datasetVersion !== id
          if (isDeprecated) {
            dispatch(
              setNotification({
                visible: true,
                type: 'warning',
                component: 'outdatedDataset',
              })
            )
          }
        }

        GFWAPI.setConfig({ dataset: datasetVersion })

        dispatch(
          checkDefaultWorkspace({
            dataset: datasetID,
            datasetVersion,
            supportedEventTypes,
            permissions: user.permissions,
          })
        )

        dispatch(setSupportedEvents(supportedEventTypes))
        dispatch(setDatasetData(dataset))
      })
      .catch((e) => {
        if (e.status > 400 && e.status <= 403) {
          dispatch({ type: SET_USER_UNAUTHORIZED })
        } else {
          dispatch(logoutUser())
        }
        console.warn('Incorrect dataset version', e)
        dispatch(setDatasetEror(e))
      })
  } else {
    dispatch({ type: SET_USER_UNAUTHORIZED })
    dispatch(setDatasetEror())
  }
}

export const checkUserLogged = () => ({ urlParams }, dispatch) => {
  const accessToken = urlParams['access-token'] || ''
  dispatch({ type: SET_USER_LOADING })

  GFWAPI.login({ accessToken })
    .then((user) => {
      if (user) {
        const canLoadMap =
          user.permissions &&
          user.permissions.find((p) => p.value === 'data-portal' && p.action === 'map.load') !==
            undefined
        if (canLoadMap) {
          dispatch({
            type: SET_USER_LOGGED,
            payload: user,
          })
          dispatch(initDataset(user))
          return true
        } else {
          dispatch({ type: SET_USER_UNAUTHORIZED })
          return false
        }
      } else {
        dispatch(setDatasetData(null))
        dispatch(logoutUser())
      }
    })
    .catch((error) => {
      console.warn(error)
      dispatch(logoutUser())
    })
}

const checkDefaultWorkspace = (config) => ({ urlParams }, dispatch) => {
  const { dataset, datasetVersion, supportedEventTypes, permissions } = config
  // Needed to recover the query params before the redirection
  const paramsFromRedirect = sessionStorage.getItem(QUERY_PARAMS_KEY)
  let params = { ...urlParams, dataset, datasetVersion, 'access-token': undefined }

  if (paramsFromRedirect) {
    params = {
      ...urlParams,
      ...qs.parse(paramsFromRedirect),
      token: undefined,
    }
    sessionStorage.removeItem(QUERY_PARAMS_KEY)
  }

  // Check mandatory url params to initialize map module
  if (
    params.center === undefined ||
    params.zoom === undefined ||
    params.start === undefined ||
    params.end === undefined
  ) {
    params = {
      ...getDefaultUrlParams(),
      ...params,
    }
  }

  // Remove the layers that user doesn't have permissions
  const userLayersIds = getUserAllowedLayers(permissions, datasetVersion)
  const urlLayers = urlParams.layers || DEFAULT_LAYERS
  let otherLayers = urlLayers.filter((type) => !userLayersIds.includes(type))
  let userLayers = supportedEventTypes
    .filter((type) => userLayersIds.includes(type))
    .map((type) => `events_${type}`)
    .filter((l) => urlLayers.includes(l))

  params.layers = uniq([...otherLayers, ...userLayers])
  dispatch(
    replace({
      search: qs.stringify(params),
    })
  )
}

export const updateDatasetVersion = () => ({ urlParams }, dispatch) => {
  GFWAPI.fetch(`/`).then(({ id: datasetVersion }) => {
    dispatch(updateURLParams({ datasetVersion }, undefined, true))
    dispatch(resetNotification())
    window.location.reload()
  })
}
