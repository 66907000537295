import {
  FETCH_VESSELS_INFO_ERROR,
  FETCHING_VESSELS_INFO,
  SET_CURRENT_VESSEL,
  SET_VESSELS_LIST,
  RESET_VESSELS,
} from './Vessels.actions'
import { RESULTS_PER_PAGE } from '../constants'

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  current: {},
  list: {
    entries: [],
    limit: RESULTS_PER_PAGE,
    nextOffset: null,
    offset: null,
    total: null,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_VESSELS:
      return initialState
    case FETCHING_VESSELS_INFO:
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    case SET_CURRENT_VESSEL:
      return {
        ...state,
        loading: false,
        loaded: true,
        current: action.payload,
      }
    case SET_VESSELS_LIST: {
      const entries =
        action.payload.offset === 0
          ? action.payload.entries
          : [...state.list.entries, ...action.payload.entries]
      return {
        ...state,
        loading: false,
        loaded: true,
        list: {
          ...action.payload,
          entries,
        },
      }
    }
    case FETCH_VESSELS_INFO_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      }
    default:
      return state
  }
}
