import { connect } from 'react-redux'
import { removeLocalToken } from '../utils/login'
import { checkUserLogged } from '../app/App.actions'
import Header from './Header'

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    removeLocalToken()
    dispatch(checkUserLogged())
  },
})

export default connect(
  null,
  mapDispatchToProps
)(Header)
