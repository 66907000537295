import { createSelector } from 'reselect'
import { getViewport } from './router'
import { getEncounterEvent } from './events'
import { TRACK_COLORS, API_GATEWAY } from '../constants'
import { getDataset } from './router'

const getTrack = (state) => state.tracks.track

let firstLoad = true
const getTrackFitBounds = createSelector(
  getViewport,
  (viewport) => {
    const isfirstLoad = firstLoad === true
    if (isfirstLoad) {
      firstLoad = false
    }
    return viewport.center !== undefined && !isfirstLoad
  }
)

export const getVesselTrack = createSelector(
  [getTrackFitBounds, getTrack],
  (trackFitBounds, track) => {
    if (track.data === null) return null
    return { ...track, fitBoundsOnLoad: trackFitBounds }
  }
)

export const getEncounterVesselTracks = createSelector(
  [getVesselTrack, getEncounterEvent, getDataset],
  (vesselTrack, encounterEvent, dataset) => {
    if (vesselTrack === null) return null
    if (encounterEvent === null) return [vesselTrack]

    const encounterVesselTrack = {
      id: encounterEvent.id,
      url: `${API_GATEWAY}/datasets/${dataset}/vessels/${encounterEvent.encounter.vessel.id}/tracks?features=fishing`,
      color: TRACK_COLORS.encountered,
      type: 'geojson',
      fitBoundsOnLoad: false,
    }
    return [vesselTrack, encounterVesselTrack]
  }
)

export const getVesselTrackEvents = createSelector(
  [getVesselTrack],
  (track) => {
    if (track === null || track.data === undefined) return null
    const trackFeatures = track.data.features
      ? track.data.features.filter((f) => f.properties.type === 'track')
      : []

    return trackFeatures.map((f) => {
      const { times } = f.properties.coordinateProperties
      return {
        type: 'track',
        id: f.properties.id,
        start: times[0],
        end: times[times.length - 1],
      }
    })
  }
)
