import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { updateURLParams } from '../actions'
import { fetchVesselsByQuery } from './Vessels.actions'
import { VESSEL_PATH } from '../constants'
import Vessels from './Vessels'
import { getUrlParams } from '../selectors/router'
import {
  getVesselsLoading,
  getVesselsLoaded,
  getVesselsList,
  getVesselsNextOffset,
} from '../selectors/vessels'

const getSearchPage = createSelector(
  getUrlParams,
  (urlParams) => urlParams && parseInt(urlParams.searchPage, 10)
)

const getSearchQuery = createSelector(
  getUrlParams,
  (urlParams) => urlParams && urlParams.query
)

const mapStateToProps = (state) => ({
  loading: getVesselsLoading(state),
  loaded: getVesselsLoaded(state),
  vessels: getVesselsList(state),
  searchPage: getSearchPage(state),
  hasMorePages: getVesselsNextOffset(state) !== null,
  searchQuery: getSearchQuery(state),
})

const mapDispatchToProps = (dispatch) => ({
  onVesselClick: (vesselId) => {
    dispatch(updateURLParams({ vesselId }, VESSEL_PATH))
  },
  updateListPage: (searchPage) => {
    dispatch(updateURLParams({ searchPage }, '/search'))
    dispatch(fetchVesselsByQuery({ page: searchPage }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vessels)
