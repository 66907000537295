import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Loader.module.css'

class Loader extends PureComponent {
  render() {
    return <div className={cx(styles.Loader, { [styles.LoaderSecondary]: this.props.secondary })} />
  }
}

Loader.propTypes = {
  secondary: PropTypes.bool,
}

Loader.defaultProps = {
  secondary: false,
}

export default Loader
