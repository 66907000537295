import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from '../app/App.reducer'
import map from '../map/Map.reducer'
import events from '../events/Events.reducer'
import vessels from '../vessels/Vessels.reducer'
import layers from '../map/Layers.reducer'
import timebar from '../timebar/Timebar.reducer'
import notifications from '../notifications/notifications.reducer'
import tracks from '../tracks/tracks.reducer'

const rootReducer = (history) =>
  combineReducers({
    app,
    map,
    events,
    vessels,
    layers,
    timebar,
    notifications,
    tracks,
    router: connectRouter(history),
  })

export default rootReducer
