import { connect } from 'react-redux'
import {
  getDatasetLoading,
  isUserLoading,
  isUserAuthorized,
  isUserLogged,
  getDatasetReady,
} from '../selectors/app'
import { updateDatasetVersion } from './App.actions'
import { checkUserLogged } from './App.actions'
import App from './App'

const mapStateToProps = (state) => {
  return {
    loading: isUserLoading(state) || getDatasetLoading(state),
    authorized: isUserAuthorized(state),
    logged: isUserLogged(state),
    datasetReady: getDatasetReady(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkUserLogged: () => {
    dispatch(checkUserLogged())
  },
  updateDatasetVersion: () => {
    dispatch(updateDatasetVersion())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
