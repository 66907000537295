export const RESULTS_PER_PAGE = 20
export const ACCESS_TOKEN_KEY = 'access_token'
export const QUERY_PARAMS_KEY = 'redirect_query_params'
export const VESSEL_PATH = '/vessel'
export const DEFAULT_DATASET_ID = 'indonesia:latest'
export const API_GATEWAY = process.env.REACT_APP_API_GATEWAY

export const EVENT_TYPES_IDS = {
  PORT: 'port',
  ENCOUNTER: 'encounter',
  UNREGISTERED: 'unregistered',
  GAP: 'gap',
  FISHING: 'fishing',
}

export const EVENT_TYPES_IDS_LIST = Object.values(EVENT_TYPES_IDS)

export const EVENT_TYPES = [
  {
    label: 'Port visits',
    id: EVENT_TYPES_IDS.PORT,
  },
  {
    label: 'Encounters',
    id: EVENT_TYPES_IDS.ENCOUNTER,
  },
  {
    label: 'Fishing in unregistered areas',
    id: EVENT_TYPES_IDS.UNREGISTERED,
  },
  {
    label: 'Tracking avoidance',
    id: EVENT_TYPES_IDS.GAP,
  },
  {
    label: 'fishing',
    id: EVENT_TYPES_IDS.FISHING,
  },
]

export const TIMEBAR_HOVER_HIGHLIGHT_RANGE_PX = 90
export const TIMEBAR_HOVER_HIGHLIGHT_HOUR_RANGE_PX = 150

export const DATE_FORMAT = 'MMM D YYYY'
export const DATE_FORMAT_LONG = 'MMM D YYYY h:mm a'

export const DEFAULT_LAYERS = ['events_gap', 'events_port', 'events_encounter']

export const TRACK_COLORS = {
  main: '#FE81EB',
  encountered: '#ff0f00',
}

export const HEATMAP = 'ClusterAnimation'
