import { workspace } from '../workspace.json'
import { HEATMAP } from '../constants'
import { SET_HEATMAP_HEADER, TOGGLE_HEATMAP_HEADER } from './Layers.actions'

const initialState = {
  layers: workspace.map.layers.filter((layer) => layer.type !== HEATMAP),
  heatmapLayer: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HEATMAP_HEADER: {
      const heatmapLayer = action.heatmapLayer
      return { ...state, heatmapLayer }
    }
    case TOGGLE_HEATMAP_HEADER: {
      const heatmapLayer = {
        ...state.heatmapLayer,
        visible: !state.heatmapLayer.visible,
      }
      return { ...state, heatmapLayer }
    }
    default:
      return state
  }
}
