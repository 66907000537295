import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './MapTools.module.css'
import LayerPanel from './LayerPanel.container'
import LanguageSelector from './LanguageSelector'
import Loader from '../loader/Loader'
import { ReactComponent as IconMinus } from '../icons/minus.svg'
import { ReactComponent as IconPlus } from '../icons/plus.svg'
import { ReactComponent as IconLayers } from '../icons/layers.svg'
import { ReactComponent as IconShare } from '../icons/share.svg'

class MapTools extends Component {
  constructor(props) {
    super(props)

    this.state = {
      layerPanelHidden: true,
    }
  }

  toggleLayerPanel = () => {
    this.setState({
      layerPanelHidden: !this.state.layerPanelHidden,
    })
  }

  render() {
    const { setViewportZoom, onShareClick, loading } = this.props

    return (
      <div>
        <button
          type="button"
          title="Zoom in"
          className={styles.actionButton}
          onClick={() => setViewportZoom(+1)}
        >
          <IconPlus />
        </button>
        <button
          type="button"
          title="Zoom out"
          className={styles.actionButton}
          onClick={() => setViewportZoom(-1)}
        >
          <IconMinus />
        </button>
        <button
          type="button"
          title="Show layer panel"
          className={styles.actionButton}
          onClick={this.toggleLayerPanel}
        >
          <IconLayers />
        </button>
        <button type="button" title="Share" onClick={onShareClick} className={styles.actionButton}>
          <IconShare />
        </button>
        <LanguageSelector />
        {loading && (
          <span className={styles.actionButton}>
            <Loader secondary />
          </span>
        )}
        {!this.state.layerPanelHidden && <LayerPanel toggleLayerPanel={this.toggleLayerPanel} />}
      </div>
    )
  }
}

MapTools.propTypes = {
  setViewportZoom: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default MapTools
