import {
  UPDATE_EVENTS,
  CLEAR_EVENTS,
  UPDATE_VESSEL_EVENTS,
  CLEAR_VESSEL_EVENTS,
  UPDATE_EVENTS_STATS,
  SET_HIGHLIGHTED_EVENTS,
  SET_HIGHLIGHTED_EVENTS_TYPE,
  LOADING_START,
  LOADING_STATS_START,
} from './Events.actions'

const initialState = {
  loading: false,
  events: null,
  vesselEvents: null,
  highlightedEvents: null,
  highlightedEventsType: null,
  highlightedEventsParentType: null,
  highlightedClusterId: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EVENTS:
      return {
        ...state,
        events: action.events,
        isLoading: false,
      }
    case CLEAR_EVENTS:
      return {
        ...state,
        events: null,
      }
    case UPDATE_VESSEL_EVENTS:
      return {
        ...state,
        vesselEvents: action.events,
        isLoading: false,
      }
    case CLEAR_VESSEL_EVENTS:
      return {
        ...state,
        vesselEvents: null,
      }
    case UPDATE_EVENTS_STATS:
      return {
        ...state,
        eventsStats: action.eventsStats,
        isStatsLoading: false,
      }
    case SET_HIGHLIGHTED_EVENTS:
      return {
        ...state,
        highlightedEvents: action.eventIds,
        highlightedEventsParentType: action.eventsType,
        highlightedClusterId: action.clusterId,
      }
    case SET_HIGHLIGHTED_EVENTS_TYPE:
      return {
        ...state,
        highlightedEventsType: action.eventType,
      }
    case LOADING_START:
      return {
        ...state,
        isLoading: true,
      }
    case LOADING_STATS_START:
      return {
        ...state,
        isStatsLoading: true,
      }
    default:
      return state
  }
}
