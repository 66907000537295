import { updateURLParams } from '../actions'
// import { workspace } from '../workspace.json'
// import { HEATMAP } from '../constants'

export const SET_HEATMAP_HEADER = 'SET_HEATMAP_HEADER'
export const TOGGLE_HEATMAP_HEADER = 'TOGGLE_HEATMAP_HEADER'

// eslint-disable-next-line import/prefer-default-export
export const toggleLayer = (layerId) => ({ urlParams }, dispatch) => {
  const layers = urlParams.layers || []
  if (layers.includes(layerId)) {
    layers.splice(layers.findIndex((l) => l === layerId), 1)
  } else {
    layers.push(layerId)
  }

  dispatch(updateURLParams({ layers }))
}

export const toggleHeatmapLayer = () => ({ urlParams }, dispatch) => {
  dispatch({
    type: TOGGLE_HEATMAP_HEADER,
  })
}
