import dayjs from 'dayjs'
import qs from 'query-string'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { getSupportedEvents } from '../selectors/app'
import { getVesselId, getDatasetVersion, getTemporalExtent } from '../selectors/router'
import { targetMapVessel } from '@globalfishingwatch/map-components/components/map'
import { updateURLParams } from '../actions'
import { fetchCurrentVessel } from './Vessels.actions'
import { resetTrack } from '../tracks/tracks.actions'
import { fetchCurrentVesselEvents } from '../events/Events.actions'
import VesselProfile from './VesselProfile'
import { API_GATEWAY, DATE_FORMAT } from '../constants'

const getVessel = (state) => state.vessels.current

const getVesselFields = createSelector(
  [getVessel],
  (vessel) => {
    return {
      ...vessel,
      gearType: `${vessel.gearTypeEng} / ${vessel.gearTypeBah}`,
      registrationStart: dayjs(vessel.registrationStartDate).format(DATE_FORMAT),
      registrationEnd: dayjs(vessel.registrationEndDate).format(DATE_FORMAT),
      length: `${vessel.length}m`,
      width: `${vessel.width}m`,
      grossTonnage: `${vessel.grossTonnage}t`,
    }
  }
)

const getDownloadUrl = createSelector(
  [getTemporalExtent, getDatasetVersion, getSupportedEvents, getVesselId],
  (temporalExtent, dataset, events, vesselId) => {
    if (!temporalExtent || !dataset || !events) return ''

    const params = qs.stringify({
      output: 'csv',
      types: events,
      vessels: vesselId,
      startDate: temporalExtent[0].toISOString(),
      endDate: temporalExtent[1].toISOString(),
    })
    const url = `${API_GATEWAY}/datasets/${dataset}/events?${params}`
    return url
  }
)

const mapStateToProps = (state) => ({
  vesselId: getVesselId(state),
  vessel: getVesselFields(state),
  downloadUrl: getDownloadUrl(state),
  loading: state.vessels.loading,
  search: state.router.location.search,
})

const mapDispatchToProps = (dispatch) => ({
  loadVessel: () => {
    dispatch(fetchCurrentVessel())
    dispatch(fetchCurrentVesselEvents())
  },
  onVesselTargetClick: (id) => {
    targetMapVessel(id)
  },
  onCloseClick: () => {
    const newParams = { query: undefined, vesselId: undefined, eventId: undefined }
    dispatch(updateURLParams(newParams, '/'))
    dispatch(resetTrack())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselProfile)
