import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { throttle } from 'lodash'
import { VESSEL_PATH } from '../constants'
import GFWAPI from '@globalfishingwatch/api-client'
import {
  getDataset,
  getTemporalExtent,
  getViewport,
  getVesselId,
  getSelectedEvent,
} from '../selectors/router'
import { getBaseLayers } from '../selectors/layers'
import { getVesselTrack, getEncounterVesselTracks } from '../selectors/tracks'
import { getSupportedEvents } from '../selectors/app'
import {
  getVesselEvents,
  getHighlightedEventsType,
  getAllHighlightedMapEvents,
} from '../selectors/events'
import { updateURLParams } from '../actions'
import { setZoom, updateViewport, setHoverCoords } from './Map.actions'
import { debouncedFetchEvents, setHighlightedEvents } from '../events/Events.actions'
import MapWrapper from './MapWrapper'
import { eventIsWithinTemporalExtent } from '../utils/eventIsWithinTemporalExtent'
import { API_GATEWAY } from '../constants'

const EVENT_TYPES_WITH_VESSEL = ['encounter', 'gap', 'port']

const getHighlightedExtent = (state) => state.timebar.highlightedExtent
const getHeatmapLayer = (state) => state.layers.heatmapLayer

const getIsShowingVessel = createSelector(
  [getVesselId],
  (vesselId) => {
    if (vesselId !== undefined && vesselId !== '') return true
    return false
  }
)

const getVesselTracks = createSelector(
  [getIsShowingVessel, getEncounterVesselTracks],
  (isShowingVessel, vesselTracks) => {
    if (isShowingVessel === false || vesselTracks === null) return undefined
    return vesselTracks
  }
)

const getVesselEventsFeatures = createSelector(
  [getVesselEvents, getSupportedEvents],
  (vesselEvents, supportedEvents) => {
    if (vesselEvents === null) {
      return null
    }
    const vesselEventsByType = {}
    supportedEvents.forEach((eventType) => {
      const eventsForType = vesselEvents.filter((event) => event.type === eventType)
      const eventsForTypeFeatures = eventsForType.map((event) => {
        const start = new Date(event.start).getTime()
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [event.position.lon, event.position.lat],
          },
          properties: {
            event_id: event.id,
            vessel_id: event.vessel.id,
            start,
            end: event.end === null ? start : new Date(event.end).getTime(),
            timestamp: start,
          },
        }
      })
      vesselEventsByType[eventType] = eventsForTypeFeatures
    })
    return vesselEventsByType
  }
)

const getVesselEventsAsGeoJSONFilteredByTime = createSelector(
  [getVesselEventsFeatures, getTemporalExtent],
  (vesselEventsByType, temporalExtent) => {
    if (vesselEventsByType === null || temporalExtent === null) {
      return null
    }
    const featureCollectionsByType = {}

    Object.keys(vesselEventsByType).forEach((eventType) => {
      featureCollectionsByType[eventType] = {
        type: 'FeatureCollection',
        features: vesselEventsByType[eventType].filter((event) => {
          return eventIsWithinTemporalExtent(
            event.properties.start,
            event.properties.end,
            temporalExtent
          )
        }),
      }
    })
    return featureCollectionsByType
  }
)

const getVesselLayers = createSelector(
  [getBaseLayers, getIsShowingVessel, getVesselEventsAsGeoJSONFilteredByTime, getDataset],
  (baseLayers, isShowingVessel, vesselEvents, dataset) => {
    const withVesselLayers = [...baseLayers]
    const withVesselLayersIds = withVesselLayers.map((l) => l.id)
    EVENT_TYPES_WITH_VESSEL.filter((e) => withVesselLayersIds.includes(`events_${e}`)).forEach(
      (eventType) => {
        const baseLayerId = `events_${eventType}`
        const vesselLayerId = `${baseLayerId}_vessel`
        const baseLayerIndex = baseLayers.findIndex((l) => l.id === baseLayerId)
        const baseLayer = { ...baseLayers[baseLayerIndex] }
        const vesselLayer = {
          ...baseLayer,
          id: vesselLayerId,
          visible: baseLayer.visible && isShowingVessel,
          url: `${API_GATEWAY}/datasets/${dataset}/events/by-type/${eventType}/tiles/{z}/{x}/{y}`,
        }
        if (vesselEvents !== null && isShowingVessel) {
          vesselLayer.data = vesselEvents[eventType]
        }
        if (isShowingVessel) {
          baseLayer.visible = false
        }
        withVesselLayers[baseLayerIndex] = baseLayer
        withVesselLayers.push(vesselLayer)
      }
    )
    return withVesselLayers
  }
)

const getMapHighlightedExtent = createSelector(
  [getHighlightedExtent, getVesselTrack],
  (extent, track) => {
    if (track === null) return null
    return extent
  }
)

const getHighlightedLayers = createSelector(
  [getVesselLayers, getHighlightedEventsType, getAllHighlightedMapEvents, getIsShowingVessel],
  (layers, highlightedEventsType, highlightedEvents, isShowingVessel) => {
    const hasHighlightedEvents =
      isShowingVessel && (highlightedEvents !== null && highlightedEvents.eventsIds.length)

    if (highlightedEventsType === null && !hasHighlightedEvents) {
      return layers
    }
    const highlightedLayers = layers.map((layer) => {
      const highlightedLayer = { ...layer }
      if (
        layer.id === `events_${highlightedEventsType}` ||
        layer.id === `events_${highlightedEventsType}_vessel`
      ) {
        highlightedLayer.highlighted = true
      }
      return highlightedLayer
    })

    if (!hasHighlightedEvents) {
      return highlightedLayers
    }
    highlightedLayers.forEach((layer) => {
      if (
        highlightedEvents.type === undefined ||
        highlightedEvents.type === layer.id.replace('_vessel', '')
      ) {
        layer.highlightedFeatures = {
          field: highlightedEvents.isCluster === true ? 'cluster_id' : 'event_id',
          values: highlightedEvents.eventsIds,
        }
      }
    })
    return highlightedLayers
  }
)

const getHeatmapLayers = createSelector(
  [getHeatmapLayer],
  (heatmapLayer) => {
    return heatmapLayer !== null ? [heatmapLayer] : null
  }
)

const mapStateToProps = (state) => ({
  token: GFWAPI.getToken(),
  tracks: getVesselTracks(state),
  temporalExtent: getTemporalExtent(state),
  highlightExtent: getMapHighlightedExtent(state),
  selectedEvent: getSelectedEvent(state),
  viewport: getViewport(state),
  layers: getHighlightedLayers(state),
  isShowingVessel: getIsShowingVessel(state),
  heatmapLayers: getHeatmapLayers(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateViewport: (viewport) => {
    dispatch(updateViewport(viewport))
    const newParams = { zoom: viewport.zoom, center: viewport.center }
    dispatch(updateURLParams(newParams, undefined, true))
    debouncedFetchEvents(dispatch)
  },
  onEventClick: (vesselId) => {
    dispatch(updateURLParams({ vesselId }, VESSEL_PATH))
  },
  onEncounterClick: (vesselId, eventId) => {
    const newParams = { vesselId, eventId }
    dispatch(updateURLParams(newParams, VESSEL_PATH))
  },
  onClusterClick: (zoom, latitude, longitude) => {
    const center = [latitude, longitude]
    dispatch(setZoom(zoom, center))
  },
  setHighlightedEvent: throttle((eventIds, type = null, clusterId = null) => {
    dispatch(setHighlightedEvents(eventIds, type, clusterId))
  }, 16),
  setHoverCoords: (latitude, longitude) => {
    dispatch(setHoverCoords(latitude, longitude))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapWrapper)
