import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { getSelectedEvent, getTemporalExtent } from '../selectors/router'
import { getVesselTrackEvents } from '../selectors/tracks'
import { VESSEL_PATH } from '../constants'
import { getVesselEvents, getHighlightedEvents, getEventsStats } from '../selectors/events'
import { updateURLParams } from '../actions'
import { debouncedFetchEvents, setHighlightedEvents } from '../events/Events.actions'
import { setHighlightedExtent } from './Timebar.actions'
import TimebarWrapper from './TimebarWrapper'

const getURLPathname = (state) => state.router.location.pathname

const getIsVesselShown = createSelector(
  [getURLPathname],
  (URLPathname) => URLPathname === VESSEL_PATH
)

const getAllVesselEvents = createSelector(
  [getVesselEvents, getVesselTrackEvents],
  (vesselEvents, vesselTrackEvents) => {
    if (vesselEvents === null) {
      return vesselTrackEvents || null
    }
    if (vesselTrackEvents === null) {
      return vesselEvents || null
    }
    return [...vesselEvents, ...vesselTrackEvents]
  }
)

const getAbsoluteExtent = createSelector(
  [getEventsStats],
  (eventStats) => {
    if (!eventStats) return ['2017-01-01T00:00:00.000Z', new Date().toISOString()]
    return [eventStats.start, eventStats.end]
  }
)

const mapStateToProps = (state) => ({
  absoluteExtent: getAbsoluteExtent(state),
  temporalExtent: getTemporalExtent(state),
  isVesselShown: getIsVesselShown(state),
  eventsStats: getEventsStats(state),
  vesselEvents: getAllVesselEvents(state),
  selectedEventID: getSelectedEvent(state),
  highlightedEvents: getHighlightedEvents(state),
})

const mapDispatchToProps = (dispatch) => ({
  setTemporalExtent: (start, end) => {
    const newParams = { start, end }
    dispatch(updateURLParams(newParams, undefined, true))
    debouncedFetchEvents(dispatch)
  },
  onEncounterClick: (eventId) => {
    const newParams = { eventId }
    dispatch(updateURLParams(newParams, VESSEL_PATH))
  },
  setHighlightedEvent: (eventId, type = null) => {
    const eventIds = eventId === null ? null : [eventId]
    dispatch(setHighlightedEvents(eventIds, type))
  },
  setHighlightedExtent: (range) => {
    dispatch(setHighlightedExtent(range))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimebarWrapper)
