import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjsPluginUTC from 'dayjs-plugin-utc'

import store, { history } from './store'
import App from './app/App.container'
import './base.css'

dayjs.extend(relativeTime)
dayjs.extend(dayjsPluginUTC)

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root')
  )
}

render()
