import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Header from './Header.container'
import Search from '../vessels/Search.container'
import Events from '../events/Events.container'

class SidebarHome extends Component {
  componentDidMount() {
    this.props.loadEvents()
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Search />
        <Events />
      </Fragment>
    )
  }
}

SidebarHome.propTypes = {
  loadEvents: PropTypes.func.isRequired,
}

export default SidebarHome
