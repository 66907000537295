import React from 'react'
import Search from '../vessels/Search.container'
import Vessels from '../vessels/Vessels.container'
import Events from '../events/Events.container'
import VesselProfile from '../vessels/VesselProfile.container'
import styles from './Sidebar.module.css'

export const sidebarElementId = 'sidebar-container'

export const Sidebar = ({ children }) => (
  <div id={sidebarElementId} className={styles.Sidebar}>
    {children}
  </div>
)

export const SidebarSearch = () => [<Search expanded key="search" />, <Vessels key="vessels" />]

export const SidebarVessel = () => [<VesselProfile key="profiles" />, <Events key="events" />]
