import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Login.module.css'
import { QUERY_PARAMS_KEY } from '../constants'
import { ReactComponent as IconLogo } from '../icons/gfw_logo.svg'
import GFWAPI from '@globalfishingwatch/api-client'

const saveUrlQuery = (e) => {
  e.preventDefault()
  // Needed to clean params as the api don't keep them after the redirect.
  const [urlPath, urlQuery] = window.location.href.split('?')
  sessionStorage.setItem(QUERY_PARAMS_KEY, urlQuery)
  const redirectUri = decodeURIComponent(urlPath)
  const url = GFWAPI.getLoginUrl(redirectUri)
  window.location = url
}

class Login extends Component {
  render() {
    const { authorized } = this.props
    const content =
      authorized === false
        ? "⚠️ Your account doesn't seem to be authorized"
        : 'Only registered users can use this tool. Please log in with your Global Fishing Watch credentials.'
    return (
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <IconLogo className={styles.logoImage} />
          <h1 className={styles.textTitle}>GlobalFishingWatch Data Portal</h1>
          <h2 className={styles.textSubtitle}>{content}</h2>
          <a className={styles.link} href="#login-page" onClick={saveUrlQuery}>
            LOG IN
          </a>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  authorized: PropTypes.bool,
}

Login.defaultProps = {
  authorized: true,
}

export default Login
