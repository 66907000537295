import {
  SET_DATASET_LOADING,
  SET_DATASET_DATA,
  SET_DATASET_ERROR,
  SET_USER_LOADING,
  SET_USER_LOGGED,
  SET_USER_UNAUTHORIZED,
  SET_SUPPORTED_EVENTS,
  SET_USER_LOGOUT,
} from './App.actions'

const initialState = {
  user: {
    loading: false,
    logged: false,
    data: null,
    authorized: true,
  },
  dataset: {
    loading: true,
    loaded: false,
    data: null,
    error: null,
  },
  events: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATASET_LOADING: {
      const dataset = { ...state.dataset, loading: action.payload }
      return { ...state, dataset }
    }
    case SET_DATASET_DATA: {
      const dataset = { ...state.dataset, loading: false, loaded: true, data: action.payload }
      return { ...state, dataset }
    }
    case SET_DATASET_ERROR: {
      const dataset = { ...state.dataset, loading: false, loaded: true, error: action.payload }
      return { ...state, dataset }
    }
    case SET_USER_LOADING: {
      const user = { ...state.user, loading: true }
      return { ...state, user }
    }
    case SET_USER_LOGGED: {
      const user = { ...state.user, loading: false, logged: true, data: action.payload }
      return { ...state, user }
    }
    case SET_USER_UNAUTHORIZED: {
      const user = { ...state.user, loading: false, logged: false, authorized: false }
      return { ...state, user }
    }
    case SET_USER_LOGOUT: {
      const user = { ...initialState.user }
      return { ...state, user }
    }
    case SET_SUPPORTED_EVENTS: {
      return { ...state, events: action.payload }
    }
    default:
      return state
  }
}
